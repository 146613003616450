<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <div class="mb-1 w-24">
              <h1 class="title">個人照 <span>*</span></h1>
              <div class="upload aspect-ratio-1-1_global_style">
                <img v-if="item.photo" :src="item.photo" class="uploadImg" />
                <div v-else class="upload_icon">
                  <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                  <p class="text-gray-400">點擊上傳或拖曳圖片</p>
                </div>
                <vs-input type="file" @change="uploadImg" accept="image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF" class="img_input" />
              </div>
              <ValidationProvider mode="aggressive" name="個人照" placeholder="請選擇個人照" rules="required" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="item.photo" style="display:none;"/>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">帳號 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="帳號" placeholder="請輸入帳號" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="item.account" :disabled=status />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">員工編號 </h1>
                <vs-input class="w-full" v-model="item.number" />
            </div>
            <div>
              <h1 class="title">職稱</h1>
                <vs-input class="w-full" v-model="item.job_title" />
            </div>
            <div>
              <h1 class="title">姓名 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="姓名" placeholder="請輸入姓名" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="item.name" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">信箱 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="信箱" placeholder="請輸入信箱" rules="required|email|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="item.email" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">電話 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="電話" placeholder="請輸入電話" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="item.tel" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">LineID <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="LineID" placeholder="請輸入LineID" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="item.lineid" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">部門 <span>*</span></h1> 
              <vs-select v-model="item.role_id" ref="role_id" color="success" @change="displaySelect">
                <vs-option :label="def_item.name" :value="def_item.id" v-for="def_item in def_roles" :key="def_item.id">
                  {{ def_item.name }}
                </vs-option>
              </vs-select>
            </div>
            <div>
              <h1 class="title">業務身分 <span>*</span></h1>
              <vs-select v-model="item.salesman_status" color="success">
                <vs-option :label="def_item.name" :value="def_item.id" v-for="def_item in def_salesmans" :key="def_item.id">
                  {{ def_item.name }}
                </vs-option>
              </vs-select>
            </div>

            <div class="flex justify-between" v-if="item.deleted_at == null">
              <div class="flex">
              <vs-button size="large" color="danger" border @click="delete_dialog = true" v-if="id != 0 && id != 1">
                <span class="font-medium">停用</span>
              </vs-button>
              <div class="w-4"></div>
              <vs-button size="large" ref="resetPawdBtn" @click="resetPawdBtn()" v-if="id != 0 && id != 1">
                <span class="font-medium">重置密碼</span>
              </vs-button>
            </div>
            <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveBtn(invalid)">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
          </ValidationObserver>
        </section>
      </main>
    </div>

    <!-- 停用會員 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要停用{{ title }}資料？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">停用的{{ title }}資料無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteBtn" size="large" transparent color="danger" ref="deleteBtn">停用</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'user',
  data() {
    return {
      status: false, //false新增true編輯
      title: '員工',
      delete_dialog: false,
      def_salesmans:[
        {
          'id': 0,
          'name': '否'
        },
        {
          'id': 1,
          'name': '是'
        }
      ],
      def_roles:[],
      id: 0,
      item:{
        photo: '',
        account: '',
        number: '',
        job_title: '',
        name: '',
        email: '',
        tel: '',
        lineid: '',
        role_id: 0,
        salesman_status: 0,
      }
    }
  },
  created() {
    this.initData();
    this.init()
  },

  methods: {
    init() {
      this.id = this.$route.params.id
      if (this.id) {
        this.status = true,
        this.$route.meta.title = `編輯${this.title}資料`
        this.loadData()
      }else{
        this.id = 0
        this.status = false,
        this.$route.meta.title = `新增${this.title}資料`
      }
    },
    initData() {
      this.$axios({
        url: `backstage/roles/roles`,
        method: 'get',
      }).then((res) => {
        if (res.data.status) {
          this.is_API_loaded = true
          this.def_roles = res.data.data
          if(this.item.role_id == 0 || this.item.role_id == null){
            this.item.role_id = this.def_roles[0]['id']
            this.$refs.role_id.valueLabel = this.def_roles[0]['name']
          } 
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    loadData() {
      this.$axios({
        url: `backstage/users/user/${this.id}`,
        method: 'get',
      }).then((res) => {
        if (res.data.status) {
          this.is_API_loaded = true
          this.item = res.data.data
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    saveBtn(invalid) {
      if (this.id) {
        this.saveSubmit(`backstage/users/user/${this.id}`, 'put', invalid);
      }else{
        this.saveSubmit('backstage/users/user', 'post', invalid);
      }
    },
    saveSubmit(url, method, invalid) {
      if (invalid) return
      if(this.item.role_id == 0 || this.item.role_id == null){
        this.item.role_id = this.def_roles[0]['id']
      }

      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: url,
        method: method,
        data: this.item,
      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
            this.$router.replace({
              name: 'users',
              query: this.$route.query
            })
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.item.photo = e.target.result
      }
      e.target.value = ''
    },
    deleteBtn() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: `backstage/users/user/${this.id}`,
        method: 'delete',
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.status) {
          this.$myVS.openNoti(res.data.message, '', undefined)
          this.$router.replace({
            name: 'users',
            query: this.$route.query
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    resetPawdBtn() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: `backstage/users/user_resetPawd/${this.id}`,
        method: 'put',
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.status) {
          this.$myVS.openNoti(res.data.message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    displaySelect(e) {
      this.def_roles.forEach((item, index) => {
        if(item.id == e) this.$refs.role_id.valueLabel = item.name
      });
    }
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.title span{
  color: #FF0000;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2 object-contain;
  width: 100%;
  height: 100%;
}
.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}
</style>
